import React from "react"
import Seo from "../../../components/seo"
import HeroPagesCenter from "../../../components/heroes/hero-pages-center"
import WhitePaperData from "../../../content/white-papers/personalization/index.json"
import DynamicForm from "../../../components/forms/dynamic-form"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import ParagraphBlock from "../../../components/misc/paragraph-block";
import ImageBlock from "../../../components/misc/image-block";
import LayoutSecondary from "../../../components/layout-secondary";

const WhitePaperPersonalizationPage = ({ intl, location }) => (
    <LayoutSecondary>
        <Seo lang={intl.locale} title={WhitePaperData[intl.locale].title} description={WhitePaperData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`secondary`} content={WhitePaperData[intl.locale]} />
        <div className={`white-paper`}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className={`white-paper-content`}>
                            {WhitePaperData[intl.locale].content.map((c, i) => {
                                if (c.type === "image-block") {
                                    return (
                                        <div key={i}>
                                            <ImageBlock content={c} />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i}>
                                            <ParagraphBlock content={c} />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`top-overflow-wrapper`}>
                            <div className={`white-paper-form`}>
                                <DynamicForm type={`whitePaperSubscription`} defaultValues={{whitePaperUrl: `personalization.pdf`}} redirect={`/forms/thank-you/`} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </LayoutSecondary>
)

export default injectIntl(WhitePaperPersonalizationPage)
